
























































































































































































































































































































































































































































































































































.record {
  padding-top: 50px;
  .title {
    margin-bottom: 50px;
    line-height: 28px;
    text-align: center;
    color: #333;
    font-weight: 500;
    font-size: 20px;
  }
  .main {
    /deep/ .el-tabs__header {
      border-bottom: none;
    }
    /deep/ .el-tabs__nav {
      border-bottom: 1px solid #e4e7ed;
    }
    /deep/ .el-tabs__item {
      width: 180px;
      height: 46px;
      line-height: 46px;
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      color: #323233;
      cursor: pointer;
      &.is-active {
        background-color: #ff6000;
        color: #fff;
      }
    }

    .el-table {
      margin-top: 17px;
    }
    /deep/ .header-cell {
      background-color: #f7f7f7; 
    }
    .column {
      color: #888;
      font-weight: 500;
      font-size: 12px;
    }
    .success {
      color: #00a028;
    }
    .fail {
      color: #ff1414;
    }
    .item-detail {
      font-size: 12px;
      font-weight: 400;
      color: #306eed;
      cursor: pointer;
    }

    .pagination-container {
      margin-top: 35px;
      text-align: right;
    }
    /deep/ .el-pager {
      .number {
        &.active {
          background-color: #ff6000;
        }
      }
    }
  }

  .reason {
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 69px;
    margin-bottom: 20px;
    background-color: #fff5ed;
    border: 1px solid rgba(241, 146, 78, 1);
    border-left: none;
    border-right: none;
    .refuse-icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
    .text {
      color: #323233;
      font-size: 14px;
    }
  }
  .info-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 69px;
    padding-right: 29px;
    .label {
      margin-right: 16px;
      color: #333;
      font-weight: 400;
      font-size: 16px;
    }
    .val {
      flex: 1;
      color: #333;
      font-weight: 400;
      font-size: 16px;
    }
    .imgs {
      flex: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .img-item {
        width: 90px;
        height: 90px;
        margin-right: 18px;
        margin-bottom: 10px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        object-fit: scale-down;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .type-info {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    color: #333;
    font-weight: 400;
    font-size: 16px;
    .label {
      margin-right: 10px;
    }
  }
}
